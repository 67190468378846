<div class="form-holder">
  <div class="safe-area">
    <!-- <img class="main-icon" src="assets/ongoing-icon.svg"> -->
    <h1 *ngIf="isLive" class="title">{{'POLL-ONGOING.TIMER-TITLE' | translate}}</h1>
    <h1 *ngIf="!isLive" class="title">{{'POLL-ONGOING.TIMER-TITLE-SCHEDULED' | translate}}</h1>
    <div id="timers">
      <div class="timer">
        <h1 class="time">{{timeDelta.days}}</h1>
        <h1 class="measurement">{{'TIMER.DAYS' | translate}}</h1>
      </div>
      <div class="timer">
        <h1 class="time">{{timeDelta.hours}}</h1>
        <h1 class="measurement">{{'TIMER.HOURS' | translate}}</h1>
      </div>
      <div class="timer">
        <h1 class="time">{{timeDelta.minutes}}</h1>
        <h1 class="measurement">{{'TIMER.MINUTES' | translate}}</h1>
      </div>
      <div class="timer">
        <h1 class="time">{{timeDelta.seconds}}</h1>
        <h1 class="measurement">{{'TIMER.SECONDS' | translate}}</h1>
      </div>
    </div>
    <div style="text-align: center;">
      <button id="button-see-voters" (click)=showVoters()>{{'TIMER.NONVOTERS' | translate}}</button>
    </div>
    <div *ngIf="sent == false" style="text-align: center;">
      <button class="blue-button secondary-button test-button" (click)=relaunchVoters()>{{'TIMER.RELAUNCH' | translate}}</button>
    </div>
    <div *ngIf="displayChangeVoters && !sent" style="text-align: center">
      <p class="description-v" (click)="changeVoters()">{{'POLL-FINISHED.CHANGE-VOTERS' | translate}}</p>
    </div>
    <div *ngIf="sent == true" style="text-align: center;">
      <button class="inactive-button">{{'TIMER.RELAUNCH' | translate}}</button>
      <div *ngIf="displayChangeVoters" style="text-align: center">
        <p class="description-v" style="margin-top: 0px" (click)="changeVoters()">{{'POLL-FINISHED.CHANGE-VOTERS' | translate}}</p>
      </div>
      <div *ngIf="timeDiffHours != null" class="clock-holder">
        <img class="clock-icon" src="assets/horologe.svg">
        <!-- <p class="description">{{'POLL-ONGOING.RESEND-TIMER' | translate}}{{inviteDate}}</p> -->
        <p class="description">{{'POLL-ONGOING.RESEND-TIME' | translate}}{{timeDiffHours}}</p>
      </div>
      <!-- <p *ngIf="timeDiffHours == 0"  class="error-description">{{'POLL-ONGOING.RESEND-MINUTES' | translate}}</p>
      <p *ngIf="timeDiffHours == 1"  class="error-description">{{'POLL-ONGOING.RESEND-HOUR' | translate}}</p>
      <p *ngIf="timeDiffHours > 1"  class="error-description">{{'POLL-ONGOING.RESEND-HOURS-1' | translate}}{{timeDiffHours}} {{'POLL-ONGOING.RESEND-HOURS-2' | translate}}</p> -->
    </div>
    <div *ngIf="showPreCloseButton == true" style="text-align: center; margin-top: 12px">
      <button class="red-button" (click)=activateEarlyClosing()>Fermeture forcée du scrutin</button>
    </div>
  </div>
</div>
