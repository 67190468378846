import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../shared/authentication.service';
import {Router} from '@angular/router';
import {LicenseData} from '../models/LicenseData';
import {LicenseService} from '../shared/licens.service';
import {ApiService} from '../shared/api.service';
import {Common} from '../shared/common';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.less', '../poll-creation/poll-creation.component.less', '../shared/common.less']
})
export class ProfileComponent implements OnInit {
  accountName: string;
  maxVoters = 10;
  licenseTime: string;
  eventCounter = 0;
  hasHotline: any;
  status = 0;
  invoices: Array<string>;
  offer: string;
  shouldDisplayDeletionWarning = false;
  constructor(
    public authenticationService: AuthenticationService,
    private licenseService: LicenseService,
    private router: Router,
    private apiService: ApiService,
    private common: Common
  ) { }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/connexion']);
  }

  backArrow() {
    this.router.navigate(['/polls/']);
  }

  shouldDisplayChangePassword() { return !this.authenticationService.isSocialUser(); }

  ngOnInit(): void {
    this.licenseService.license.subscribe((res: LicenseData) => {
      this.hasHotline = res.hasHotline;
      this.maxVoters = res.votes;
      this.eventCounter = res.events;
      switch (res.duration) {
        case 'Y': {
          this.licenseTime = 'Y';
          break;
        }
        case 'DM': {
          this.licenseTime = 'DM';
          break;
        }
        default:
          this.licenseTime = 'D';
      }
      this.accountName = this.authenticationService.getUserData();
      if (this.maxVoters <= 10) {
        switch (this.common.getLangCode()) {
          case 'fr': { this.offer = 'Utilisation illimitée jusqu\'à 10 votants, SMS inclus.'; break; }
          case 'es': { this.offer = 'Utilisation illimitée jusqu\'à 10 votants, SMS inclus.'; break; }
          default: this.offer = 'Utilisation illimitée jusqu\'à 10 votants, SMS inclus.';
        }
      } else {
        switch (this.common.getLangCode()) {
          case 'fr': { this.offer = 'Utilisation illimitée jusqu\'à 10 votants, SMS inclus.'; break; }
          case 'es': { this.offer = 'Utilisation illimitée jusqu\'à 10 votants, SMS inclus.'; break; }
          default: this.offer = 'Utilisation illimitée jusqu\'à 10 votants, SMS inclus.';
        }
      }
    });
    this.apiService.getInvoices().subscribe((res: Array<string>) => {
      this.invoices = res;
    });
  }

  setStatus(status: number) {
    this.status = status;
  }

  displayDeleteWarning() {
    this.shouldDisplayDeletionWarning = true;
  }

  deleteAccount(deletion: boolean) {
    this.shouldDisplayDeletionWarning = false;
    if (deletion) {
       this.apiService.deleteUser().subscribe(res => {
        this.logout();
       });
    }
  }

  changePassword() {
    this.authenticationService.changePassword(this.accountName);
  }

}
