import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { WebAuth } from 'auth0-js';
import { ApiService } from './api.service';
import { ContactData, ContactFormData } from '../models/ContactData';
import { User } from '../models/User';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    public webAuth: WebAuth;

    constructor(
      private http: HttpClient,
      private apiService: ApiService
    ) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.webAuth = new WebAuth({
          domain: environment.auth.domain,
          clientID: environment.auth.clientId
        });
    }

    loginWithSocial(provider: string) {
      this.webAuth.authorize({
        connection: provider,
        responseType: 'token',
        audience: environment.auth.audience,
        redirectUri: environment.auth.redirectUri
      });
    }

    async subscribe(metadata, callback) {
        await this.webAuth.signup({
          connection: metadata.provider,
          email: metadata.mail,
          password: metadata.password,
          user_metadata: { newsletter: metadata.newsletter, referral: metadata.referral, lang: metadata.language }
        }, (err, res) => {
          if (err) callback(err)
          else {
            if (metadata.newsletter == "true")
              this.apiService.updateNewsletter(metadata.mail).subscribe((res) => {
            })
            callback(200)
          }
      });
    }

    setUserData(token: string) {
      this.webAuth.client.userInfo(token, (err, user) => {
        console.log(user)
        localStorage.setItem('userData', JSON.stringify(user));
      });
    }

    getIfUserMailPass(): boolean {
      const userData = JSON.parse(localStorage.getItem('userData'));
      const subKey = 'sub';
      return userData !== null && userData[subKey] != null && userData[subKey].toString().substring(0, 4) === 'auth';
    }

    changePassword(email: string): Observable<any> {
      let returnAPI: Promise<any>;
      return this.webAuth.changePassword({
        connection: 'Username-Password-Authentication',
        email: email
      }, (err, res) => {
        console.log(res);
        console.log(err.statusCode);
        returnAPI = err.statusCode.toPromise();
        // if (err) return err.statusCode.toPromise()
        // return err.statusCode
      });
    }

    getUserData() {
      const data = JSON.parse(localStorage.getItem('userData'));
      const emailKey = 'email';
      return data !== null && data[emailKey] != null ? data[emailKey] : '';
    }

    isSocialUser(): boolean {
      const data = JSON.parse(localStorage.getItem('userData'));
      const subKey = 'sub';
      const sub = data[subKey];
      return sub.substring(0, 5) !== 'auth0';
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    public setUserValue(token: string) {
      const newUser = new User();
      newUser.token = token;
      localStorage.setItem('currentUser', JSON.stringify(newUser));
      this.setUserData(token);
      this.currentUserSubject.next(newUser);
    }

    public getSuperAdmin(account: string): boolean {
      return environment.superAdmin === account;
    }

    public sendContactMail(message: ContactData) {
      return this.http.post(`${environment.settings.api}/home/contact`, message);
    }

    public sendContactForm(form: ContactFormData) {
      return this.http.post(`${environment.settings.api}/home/contactform`, form);
    }

    public ping$(): Observable<any> {
      return this.http.get(`${environment.settings.api}/auth/me`);
    }

    logout() {
        // remove user from local storage and set current user to null
        this.webAuth.logout({returnto: environment.auth.redirectUri, clientID: environment.auth.clientId});
        localStorage.removeItem('currentUser');
        localStorage.removeItem('userData');
        this.currentUserSubject.next(null);
    }
}
