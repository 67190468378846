<div class="form-holder" id="recap-panel">
  <div class="safe-area">
    <!--
      Recap title + Modify button in case of life vote
    -->
    <!-- <h1 *ngIf=!shouldDisplayLive class="title">{{'POLL-CREATION.CONFIRMATION.RECAP-TITLE' | translate}}</h1> -->
    <div class="live-title">
      <h1 class="title">{{'POLL-CREATION.CONFIRMATION.RECAP-TITLE' | translate}}</h1>
    </div>
    <!--
      Global information about the poll
    -->
    <div class="info-line">
      <img class="item-image" src={{typeImage}}>
      <span class="image-description">{{typeLabel}}</span>
    </div>
    <div class="separator"></div>
    <div class="info-line">
      <img class="item-image" src="assets/title-icon.svg">
      <span class="image-description">{{poll.title}}</span>
    </div>
    <div class="separator"></div>
    <div class="info-parent">
      <div class="info-line-secondary">
        <img class="item-image" src="{{votingTypeIcon}}">
        <span class="image-description">{{questionsLabel}}</span>
      </div>
      <span class="image-description">{{secretLabel}}</span>
    </div>
    <div class="separator"></div>
    <div class="info-parent">
      <div class="info-line-secondary">
        <img class="item-image" src="assets/election-simple.svg">
        <span class="image-description">{{votersCountString}} {{'POLL-CREATION.CONFIRMATION.VOTERS' | translate}}</span>
      </div>
      <span *ngIf="!poll.is2FA" class="image-description">{{smsLabel}}</span>
      <span *ngIf="poll.is2FA" class="image-description">{{'POLL-CREATION.CONFIRMATION.2FA' | translate}}</span>
    </div>
    <div class="separator"></div>
    <div *ngIf="poll.isAssessors" class="info-parent">
      <div class="info-line-secondary">
        <img class="item-image" src="assets/election-simple.svg">
        <span class="image-description">{{numberOfAssessors}} {{"ASSESSORS.ASSESSORS-LC" | translate}}</span>
      </div>
    </div>
    <div class="separator" *ngIf="poll.isAssessors"></div>
    <div class="info-line">
      <img class="item-image" src="assets/euro.svg" style="margin-top: 2.5px;">
      <span class="paid-description">{{priceString}}</span>
    </div>
    <!--
      Description of the current offer
    -->
    <h5 *ngIf="maxVoters > 10" class="image-description">{{paymentString}}</h5>
    <h5 *ngIf="maxVoters <= 10" class="image-description">{{'POLL-CREATION.CONFIRMATION.FREE-DESCRIPTION' | translate}}</h5>
    <h5 *ngIf="maxSMSVoters > 10" class="image-description">{{'POLL-CREATION.CONFIRMATION.SMS-DESCRIPTION' | translate}}
      {{maxSMSVoters.toLocaleString()}}</h5>
    <!--
      Buttons depending on the poll type and fields filled
    -->
    <button *ngIf="(poll.temporality !='live' && isPollComplete && isNumbersValid && !editionMode)"
            class="preview-button" (click)="seePreview()">{{'POLL-CREATION.CONFIRMATION.PREVIEW' | translate}}</button>
    <button *ngIf="!isNumbersValid && maxVoters > 10" class="publish-button"
      (click)="publish()">{{'POLL-CREATION.CONFIRMATION.CHANGE-PAYMENT' | translate}}</button>
      <!-- Invalid buttons for live vote before registering emails and after confirming mails -->
      <!-- <button *ngIf="poll.temporality =='live' && !canLaunchLive && !shouldDisplayLive && isNumbersValid"
      class="publish-button-invalid">{{'POLL-CREATION.CONFIRMATION.GO-LIVE' | translate}}</button> -->
    <disabled-button *ngIf="poll.temporality =='live' && !canLaunchLive && !shouldDisplayLive && isNumbersValid"
      [buttonText]="'POLL-CREATION.CONFIRMATION.GO-LIVE' | translate"></disabled-button>
    <button *ngIf="(poll.temporality == 'live') && shouldDisplayLive && canBeClosed && isNumbersValid"
            class="blue-button-bold" style="width: 100%;" (click)="closeLive()">{{'POLL-CREATION.CONFIRMATION.CLOSE-LIVE' | translate}}</button>
      <!-- Invalid button for planned vote when fields aren't filled -->
      <button *ngIf="(poll.temporality != 'live' && !isPollComplete && isNumbersValid)"
      class="publish-button-invalid">{{'POLL-CREATION.CONFIRMATION.LAUNCH-POLL' | translate}}</button>
      <!-- Confirm button on live vote when emails are valid. Display poll creation page -->
    <button *ngIf="poll.temporality == 'live' && canLaunchLive && !shouldDisplayLive && isNumbersValid" class="publish-button"
      (click)="goLive()">{{'POLL-CREATION.CONFIRMATION.GO-LIVE' | translate}}</button>
      <!-- Confirm button for planned poll -->
    <button *ngIf="(poll.temporality !='live' && isPollComplete && isNumbersValid && !editionMode)"
      class="publish-button planned" (click)="publish()">{{'POLL-CREATION.CONFIRMATION.LAUNCH-POLL' | translate}}</button>
    <!-- Confirm button for live poll -->
    <button *ngIf="(poll.temporality =='live' && isPollComplete && isNumbersValid && shouldDisplayLive)"
            class="publish-button planned" (click)="publish()">{{'POLL-CREATION.CONFIRMATION.LAUNCH-POLL' | translate}}</button>
    <button *ngIf="(poll.temporality !='live' && isPollComplete && isNumbersValid && editionMode)"
      class="publish-button planned" (click)="sendEdition()">{{'POLL-CREATION.CONFIRMATION.CONFIRM-EDITION' | translate}}</button>
      <!-- Buy an offer button -->
    <button *ngIf="!isNumbersValid && maxVoters <= 10" class="publish-button get-offer"
      (click)="publish()">{{'POLL-CREATION.CONFIRMATION.CHOICE-PAYMENT' | translate}}</button>

  </div>
</div>
